<mat-card class="property-card" (click)="viewProperty()">
	<div class="container">
		<div class="address">{{property.address.fullAddress}}</div>
		<img class="property-thumbnail" src="{{ firstOf(property.propertyPhotos)?.url }}" alt="Property thumbnail" onerror="this.src='./assets/no_photo_455x340.jpg'" />
		<div class="price-detail">
			${{ addCommasToPrice(property.propertyFeatures['featureData'].ListPrice) }}
		</div>
		<div class="small-details bottom">
			{{ (property?.propertyFeatures['featureData'].BedroomsTotal && property?.propertyFeatures['featureData'].BedroomsTotal !== '********') 
    			? property?.propertyFeatures['featureData'].BedroomsTotal 
    			: 'N/A' }} bd / {{ bathroomCalculation(property) }} ba
			<div class="divider"></div>
			{{ (environment.originatingMlsList.includes(property?.originatingMls?.toLowerCase())) 
				? (property?.propertyFeatures['featureData'].BuildingAreaTotal !== '********' && property?.propertyFeatures['featureData'].BuildingAreaTotal || 'N/A')
				: (property?.propertyFeatures['featureData'].LivingArea !== '********' && property?.propertyFeatures['featureData'].LivingArea || 'N/A') }} Sq. Ft
		</div>
	</div>
</mat-card>