import { Component, OnInit, EventEmitter, Output, Input, AfterViewInit,SimpleChanges } from '@angular/core';
import { PropertySearch, PropertyFilter, Property } from '../propertySearch'
import { unixTimestampToDate, isValidDate, formatTourDate, formatShowingDate, addCommasToPrice } from '../utils'

// import { GoogleMapsModule } from '@angular/google-maps'
import { MapsService } from '../services/maps.service'
import { ToursService } from '../services/tours.service'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// import { MatListModule } from '@angular/material/list';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatCardModule } from '@angular/material/card';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatSelectModule } from '@angular/material/select';
// import { MatExpansionModule } from '@angular/material/expansion';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatGridListModule } from '@angular/material/grid-list';
// import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSort, MatSortModule, MatSortable } from '@angular/material/sort';
// import { MatPaginatorModule } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { environment } from 'src/environments/environment';
// import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
const FILTER_PAG_REGEX='/^[0-9]/g';
@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.css']
})
export class PropertyListComponent implements OnInit {

	// @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	// properties = new MatTableDataSource()

	@Input()
	properties: Property[] = []

	@Input()
	error: string = null

	@Input()
	showCheckbox: boolean = true

	@Input()
	showAddToTours: boolean = true

	@Output()
	onAppendToTour: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onConfirmTour: EventEmitter<any> = new EventEmitter<any>()

	dataSource: MatTableDataSource<Property>;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('searchTable', { static: true }) searchTable: MatTable<any>;


	/**
 	 How many tours has this person already created
	*/
	toursCount = 0

	/**
 	 How many properties are currently checked
	*/
	checkedCount = 0


	/**
 	 Whether all the top checkbox was ticked
	*/
	selectAllChecked: boolean = false

	/**
 	 Columns for property details 
	*/
	displayedColumns: string[] = ['checkbox', 'address', 'status', 'price', 'price-change', 'bed-bath', 'sqft', 'lot', 'year-built', 'dom', 'agent-name', 'firm', 'mls', 'caret'];


	pageSize: number = 15; // Number of properties to display per page
  	currentPage: number=1; // Current page number
  	totalNumberOfPages: number = 0; // Total number of pages
  	showLoadMore: boolean = false;
	checkedPropertyIds: any = [];
	pageNumber: number = 1;
	pageIndex:number=0;
	// dataSource = this.properties.slice(0, this.currentPage * this.pageSize);
	originalProperties: Property[];
	private tourFlow_Id = null

	constructor(
		private router:Router,
		protected route: ActivatedRoute,
		// private mapsService:MapsService,
		private toursService: ToursService,
		private localStorage: LocalStorage,
		// private gaService: GoogleAnalyticsService
	) { 

			let map = this.route.snapshot.queryParams
			if ( map["tour"] != null ) {
				this.tourFlow_Id = map["tour"]
			}

			this.toursService
				.fetchToursCount()
				.subscribe( response => {

		  			// Capture server error messages
		  			if ( response["errors"] != undefined ) {
		  				this.error = response["errors"][0].message
		  				return
		  			}

		  			this.toursCount = Number(response['data'].toursCount)

				})
				// this.dataSource = new MatTableDataSource(this.properties);
				
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['properties'] && !changes['properties'].firstChange) {
			this.paginator.pageIndex = 0;
		}
		this.originalProperties = [...this.properties];
		this.dataSource = new MatTableDataSource(this.properties);
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		// if(changes['properties']){
			// console.log("object",this.properties,"changes",changes['properties']);
			// localStorage.removeItem('selectedProperty');
			// localStorage.removeItem('checkedProperties');
			// this.properties.forEach(property => property.checked = false);
			// let checkedProperties = this.properties.filter(property => property.checked);
			// this.checkedCount = 0;
			// this.selectAllChecked = false;
			// this.localStorage.setItem('checkedProperties', checkedProperties).subscribe(() => {
			//   });
			// this.checkedCount=0;
			// this.selectAllChecked =false;
			// this.properties.forEach(property => property.checked = false);
			
		// }
		this.pageIndex=changes['properties']['firstChange']?Number(localStorage.getItem('pageIndex')):0;
		const storedPageSize =Number(localStorage.getItem('pageSize'));
		let recordsPerPage =storedPageSize?storedPageSize:this.pageSize;
		let totalRecords = this.properties?.length;
		this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
		this.pageNumber =  Math.ceil(this.pageIndex + 1 / recordsPerPage);
		console.log("object","pageSize",recordsPerPage,"length",totalRecords,this.totalNumberOfPages);

	}
  /** checkbox work fn
   * 
   * @onSelectAllProperties
   * @isAllCheckBoxChecked
  */
//   checkOneCheckbox(ev:any){
//     return;
//   }
//   checkAllCheckBox(ev: any) {
//     if(!this.properties.length)return;
//     this.properties.forEach(x => x.checked = ev.target.checked)
//     this.selectedUserIds = ev.target.checked?this.properties.filter(property => property.checked):null;
//   }
  isAllCheckBoxChecked() {
    if(this.properties.length>0){
    return this.properties.every(p => p.checked);
    }
  }

	// ngOnInit(): void {
	// 	this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	// 	if (this.showCheckbox == false) {
	// 	  this.displayedColumns.shift();
	// 	}
	  
	// 	// Retrieve the previously stored checked properties from localStorage
	// 	this.localStorage.getItem('checkedProperties').subscribe((checkedProperties: Property[]) => {
	// 	  if (checkedProperties) {
	// 		// Update the checked state of the properties based on the retrieved data
	// 		this.properties.forEach(property => {
	// 		  property.checked = checkedProperties.some(checkedProperty => checkedProperty.id === property.id);
	// 		});
	  
	// 		// Update the selectAllChecked flag
	// 		this.checkedCount = checkedProperties.length;
	// 		this.selectAllChecked = this.checkedCount === this.properties.length;
	// 	  }
	// 	});
	// }

	
	ngOnInit(): void {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		if (this.showCheckbox == false) {
		  this.displayedColumns.shift();
		}
		const storedProperty = localStorage.getItem('selectedProperty');
		const storedPageSize = localStorage.getItem('pageSize');
		const storedSortProperty = JSON.parse(localStorage.getItem('sortProperty'));

		if (storedPageSize) {
			this.pageSize = Number(storedPageSize);
		}

		const storedCurrentPage = localStorage.getItem('currentPage');
		if (storedCurrentPage) {
			this.currentPage = Number(storedCurrentPage);
			if(this.paginator && this.paginator.pageSize){
				this.paginator.pageSize = this.pageSize;
				this.paginator.pageIndex = this.currentPage;
			}
		}
		if(storedSortProperty){
			if(storedProperty) {
				this.localStorage.getItem<Property[]>('checkedProperties').subscribe((checkedProperties: Property[]) => {
					if (checkedProperties) {
					this.properties.forEach(property => {
						property.checked = checkedProperties.some(checkedProperty => checkedProperty.id === property.id);
					});
					this.originalProperties = [...this.properties];
					let event = {active: storedSortProperty.active, direction: storedSortProperty.direction};
					if(storedSortProperty.direction != ""){
						this.sort.sort(({ id: storedSortProperty.active, start: storedSortProperty.direction}) as MatSortable);
					}
					else{
						this.onSortChange(event,this.searchTable);
					}
					}
				});
			} else{
				let event = {active: storedSortProperty.active, direction: storedSortProperty.direction};
				if(storedSortProperty.direction != ""){
					this.sort.sort(({ id: storedSortProperty.active, start: storedSortProperty.direction}) as MatSortable);
				}
				else{
					this.onSortChange(event,this.searchTable);
				}
			}
		}
		this.router.events.subscribe((event) => {
		if (event instanceof NavigationEnd) {
			const currentUrl = event.urlAfterRedirects;
	
			// Check if the current URL doesn't contain /properties or /property/propertyid
			// if (currentUrl.indexOf('/properties') === -1 && !currentUrl.match(/^\/property\/\w+$/)) {
			if (currentUrl.indexOf('/properties') === -1 && !currentUrl.match(/^\/property\/\w+([;?].*)?$/)) {
			console.log("this is coming back to the if condition and removing------------");
			// If the URL doesn't match the expected patterns, remove storedProperty from localStorage
			localStorage.removeItem('selectedProperty');
			localStorage.removeItem('checkedProperties');
			localStorage.removeItem('pageSize');
			localStorage.removeItem('pageIndex');
			localStorage.removeItem('currentPage');
			localStorage.removeItem('sortProperty');
			this.properties.forEach(property => property.checked = false);
			let checkedProperties = this.properties.filter(property => property.checked);
			this.checkedCount = 0;
			this.selectAllChecked = false;
			this.localStorage.setItem('checkedProperties', checkedProperties).subscribe(() => {
			  });
			}
		}
		});
		this.toursService.fetchTours().subscribe(response => {
		  // Capture server error messages
		  if (response["errors"] != undefined) {
			this.error = response["errors"][0].message;
			return;
		  }	  
		  if(storedProperty) {
			// Retrieve the previously stored checked properties from localStorage
			// let checkedCount = 0;
			this.localStorage.getItem<Property[]>('checkedProperties').subscribe((checkedProperties: Property[]) => {
				if (checkedProperties) {
				// Update the checked state of the properties based on the retrieved data
				this.properties.forEach(property => {
					property.checked = checkedProperties.some(checkedProperty => checkedProperty.id === property.id);
					// if(property.checked)checkedCount++;
				});
				console.log("object-checked",this.properties);
				// this.checkedCount = checkedCount;
				this.selectAllChecked = this.properties.every(p => p.checked);
				// this.selectAllChecked = this.checkedCount === this.properties.length;
				}
			});
		  }
		});
	}
	showMore() {
		this.currentPage++;
	}
	  

	/**
 	 Track the number of checked properties
	*/
	onPropertyChecked(property) {
		// if (property.checked) {
		//   this.checkedCount += 1;
		//   this.checkedPropertyIds.push(property.id);
		// } else {
		//   this.checkedCount -= 1;
		//   this.checkedPropertyIds = this.checkedPropertyIds.filter(id => id !== property.id);
		// }
		// this.properties.forEach(x => x.checked = event.checked)
		if(!(this.properties.length>0)) return;
		// this.checkedPropertyIds = this.properties.filter(property => property.checked);
		// this.selectAllChecked = this.checkedCount === this.properties.length;
		
		// Get the checked properties
		const checkedProperties = this.properties.filter(property => property.checked);
		this.checkedPropertyIds =checkedProperties;
		// Store the checked properties in localStorage
		this.localStorage.setItem('checkedProperties', checkedProperties).subscribe(() => {
			// Storage update successful
		});
	  }
	/**
 	 Disabled states
	*/	
	shouldDisableAddToTour() {

		// The button will change to `Create Tour` if he has none
		// if ( this.toursCount < 1 ) {
		// 	return false
		// }

		// Otherwise disabled relies on properties being checked
		// return this.checkedCount < 1
		const checkedProperties = this.properties.filter(property => property.checked);
		this.checkedPropertyIds =checkedProperties;
		return this.checkedPropertyIds.length>0?false:true;
	}

	/**
 	 Title of the `Add to tour` button
	*/
	addToTourTitle() {
		if ( this.toursCount < 1 ) {
			return "Create New Tour"
		}

		return "Add To Tour"
	}

 	/**
 	 Tick on or off all rows
	*/
	onSelectAllProperties(event: any) {
		if(!this.properties.length)return;
		this.properties.forEach(x => x.checked = event.checked)
		// this.checkedPropertyIds = event.checked?this.properties.filter(property => property.checked):null;
		// this.selectAllChecked = !this.selectAllChecked;
		// Update the checked state and count for each property
		// this.properties.forEach(property => {
		//   property.checked = this.selectAllChecked;
		//   if (this.selectAllChecked) {
		// 	this.checkedCount += 1;
		// 	if (!this.checkedPropertyIds.includes(property.listingKey)) {
		// 	this.checkedPropertyIds.push(property.listingKey);
		// 	}
		//   } else {
		// 	this.checkedCount = 0;
		// 	this.checkedPropertyIds = [];
		//   }
		// });
		const checkedProperties = this.properties.filter(property => property.checked);
		this.checkedPropertyIds =checkedProperties;
		// Store the checked properties in localStorage
		this.localStorage.setItem('checkedProperties', checkedProperties).subscribe(() => {
			// Storage update successful
		});
	  }


	onAddToTour() {
		// this.gaService.sendCtaClickEvent({
		// 	page_name: 'properties'},{
		// 	cta_type: 'button',
		// 	cta_location: 'properties_lists',
		// 	cta_description: `add_to_tour`,
		// 	property_ids: this.checkedPropertyIds
		//   });
		// if ( this.toursCount < 1 ) {
		// 	this.router.navigate([`/new-tour`])
		// 	return
		// }
 
		this.onAppendToTour.emit()
	}

	bathroomCalculation(property) {
		//var obj = JSON.parse(property.propertyFeatures['featureData'])
		// var obj = property.propertyFeatures['featureData']
		// let bathroomsFull = obj['BathroomsFull']
		// let bathroomsHalf = obj['BathroomsHalf']

		// if(Number.isNaN(bathroomsFull)) bathroomsFull = 0;
		// if(Number.isNaN(bathroomsHalf)) bathroomsHalf = 0;


		// let all = Number(bathroomsFull) + Number(bathroomsHalf) / 2
		var obj = property
		let all = obj['BathroomsTotalInteger'];

		return `${all}`


	}


	/**
 	 Modify the value into one that can be better displayed
	*/
	formatValue( property: Property, key: string) {

		// ['checkbox', 'address', 'status', 'price', 'bed-bath', 'sqft', 'year-built', 'dom', 'agent-name', 'firm', 'mls'];
	//	var obj = JSON.parse(property.propertyFeatures['featureData']) ;
		// var obj = property.propertyFeatures['featureData'] ;
		var obj = property ;
		switch( key ) {
			case 'address': 
			//	return `${property.streetNumber} ${property.address.streetName} ${property.address.unitNumber || ''}`
				let address = ''; 
				// if(obj.UnparsedAddress !== undefined && obj.UnparsedAddress != 'undefined'){
				// 			if(obj.UnparsedAddress != '')  {  address += obj.UnparsedAddress }
				// 		else{
				// 			if(obj.StreetNumber !== undefined && obj.StreetNumber != 'undefined') address += obj.StreetNumber
				// 			if(obj.streetName !== undefined  && obj.streetName != 'undefined') address += ' '+obj.streetName 
				// 		}
							
				// }else{
				// 		if(obj.StreetNumber !== undefined && obj.StreetNumber != 'undefined') address += obj.StreetNumber
				// 		if(obj.streetName !== undefined  && obj.streetName != 'undefined') address += ' '+obj.streetName 
				// }
				address = obj.UnparsedAddress;

				return `${address}`
				break

			// See https://trestle-documentation.corelogic.com/metadata-reso.html#restrictions
			case 'status':
				return this.statusDisplayName(property)
				break
			case 'price':				
				
				return `$${addCommasToPrice(obj['ListPrice'])}`
				break
			case 'price-change':
				if ( obj['ListPrice'] < obj['OriginalListPrice'] ) {
					property.priceChange = -1
					return '⬇'
				} else if (obj['ListPrice'] > obj['OriginalListPrice'] ) {

					property.priceChange = 1
					return '⬆'
				} else {

					property.priceChange = 0
					return '-'
				}
				break
			case 'bed-bath':
				let beds = obj['BedroomsTotal'];
				// let bathrooms = this.bathroomCalculation(property);
				let bathrooms = obj['BathroomsTotalInteger'];
				// Handle null value for bathrooms
				if (bathrooms === "null") {
					bathrooms = "0";
				}
				return `${beds || 0}/${bathrooms}`;
				break;
			case 'year-built':
				return  obj['YearBuilt']
			case 'sqft':
				return  addCommasToPrice(obj['LivingArea'])
				break
			case 'lot':
				// let acreage =  obj['LotSizeAcres'] != null && obj['LotSizeAcres'] != undefined && obj['LotSizeAcres'] !=''?obj['LotSizeAcres']:'N/A'
				// return acreage
				let acreage = obj['LotSizeAcres'] != null && obj['LotSizeAcres'] != undefined && obj['LotSizeAcres'] !== ''
						? parseFloat(obj['LotSizeAcres']).toFixed(2)
						: 'N/A';
				return acreage;
				break
			// case 'dom':
			// 	const daysOnMarket = obj['DaysOnMarket'];
			// 	return daysOnMarket !== undefined && daysOnMarket !== null && daysOnMarket !== '' ? daysOnMarket : 0;
			// 	break
			case 'dom':
				const daysOnMarket = obj['DaysOnMarket'];
				const originatingMls = obj['originatingMls']?.toLowerCase();

				if (environment.domMlsList.includes(originatingMls)) {
					return daysOnMarket !== undefined && daysOnMarket !== null && daysOnMarket !== '' ? daysOnMarket : '-';
				} else {
					return daysOnMarket !== undefined && daysOnMarket !== null && daysOnMarket !== '' ? daysOnMarket : 0;
				}
				// return daysOnMarket !== undefined && daysOnMarket !== null && daysOnMarket !== '' ? daysOnMarket : 0;
				break
			case 'agent-name':
				return  obj['ListAgentFullName']
				break
			case 'firm':
				return  obj['ListOfficeName']
				break

			/**
				From Trestle slack support:
 				Hi @Miles Alden The MLS number corresponds to the field "ListingId" in Trestle. Is that what you're referencing?
				As for LotSize, there is "LotSizeArea" and "LotSizeAcres", along with "LotSizeUnits". As well, there is a "LotFeatures" field with various values.
				Let us know if you need further clarification. Thanks!
			*/
			case 'mls':
				return property.listingId
		}
	}

	/**
 	 Slightly different values for use when sorting, such as `fullAddress` & price without commas.
	*/
	valueForSorting(property: Property, key: string) {

	//	var obj = JSON.parse(property.propertyFeatures['featureData']) ;
		// var obj = property.propertyFeatures['featureData'] ;
		var obj = property;

		switch( key ) {

			case 'address': 
				return Number(property.address.streetNumber ?? 0)
				break

			// See https://trestle-documentation.corelogic.com/metadata-reso.html#restrictions
			case 'status':
				return this.statusDisplayName(property)
				break

			case 'price':
				return Number(obj['ListPrice'])
				break

			case 'price-change':

				if ( obj['BedroomsTotal'] < obj['BedroomsTotal']) {
					return -1
				} else if ( obj['BedroomsTotal'] > obj['BedroomsTotal']) {
					return 1
				} else {
					return 0
				}
				break

			case 'bed-bath':
				let beds = obj['BedroomsTotal']
				return `${beds}/${obj['BathroomsTotalInteger']}`
				break
			case 'year-built':
				return Number(obj['YearBuilt'] ?? 0)
				break
			case 'sqft':
				return Number(obj['LivingArea'] ?? 0)
				break
			case 'lot':
				return Number(obj['LotSizeAcres'] ?? 0)
				break
			case 'dom':
				return Number(obj['DaysOnMarket'] ?? 0)
				break
			case 'agent-name':
				return  obj['ListAgentFullName']
				break
			case 'firm':
				return obj['ListOfficeName']
				break 

			/**
				From Trestle slack support:
 				Hi @Miles Alden The MLS number corresponds to the field "ListingId" in Trestle. Is that what you're referencing?
				As for LotSize, there is "LotSizeArea" and "LotSizeAcres", along with "LotSizeUnits". As well, there is a "LotFeatures" field with various values.
				Let us know if you need further clarification. Thanks!
			*/
			case 'mls':
				return Number(property.listingId)
		}

	}


	/**
 	 Sorting
	*/
	onSortChange(event, table) {
		// this.gaService.sendCtaClickEvent({
		// 	page_name: 'properties'},{
		// 	cta_type: 'label',
		// 	cta_location: 'properties_list',
		// 	cta_description: `sort_${event.active=='bed-bath'?'beds_baths':event.active=='mls'?'mls_id':event.active=='year-built'?'year_built':event.active}`,
		// 	sort_cta_type: event.direction === 'asc' ? 'ascending' : 'descending',
		//   });
		localStorage.setItem('sortProperty',JSON.stringify(event));
		function isValidFractionFormat(value) {
		  // Regular expression to match the "x/y" format
		  const fractionPattern = /^\d+\/\d+$/;
		  return fractionPattern.test(value);
		}
	  
		const parseValue = (str) => {
		  const [left, right] = str.split('/');
		  return { left: parseInt(left, 10), right: parseInt(right, 10) };
		};

		if (!event.direction) {
			this.dataSource.data = [...this.originalProperties];
		}
		else {
			this.dataSource.data.sort((lhs, rhs) => {
				const lhsValue = this.valueForSorting(lhs, event.active);
				const rhsValue = this.valueForSorting(rhs, event.active);
			
				  if (lhsValue === null || lhsValue === 0 || lhsValue === 'null/null'|| lhsValue === '0/0') {
					  if (rhsValue === null || rhsValue === 0 || rhsValue === 'null/null' || rhsValue === '0/0') {
					  return 0; // Both values are null or 0, maintain their relative order
					  } else {
					  return 1; // lhsValue is null or 0, move it to the end
					  }
				  } else if (rhsValue === null || rhsValue === 0 || rhsValue === 'null/null' || rhsValue === '0/0') {
					  return -1; // rhsValue is null or 0, move it to the end
				  }
	  
				if (isValidFractionFormat(lhsValue) && isValidFractionFormat(rhsValue)) {
				  const lhsParsed = parseValue(lhsValue);
				  const rhsParsed = parseValue(rhsValue);
	  
	  
				  if (event.direction === 'asc') {
					  if (lhsParsed.left < rhsParsed.left) {
						  return -1; // Corrected to -1 for ascending order
						} else if (lhsParsed.left > rhsParsed.left) {
						  return 1; // Corrected to 1 for ascending order
						}
					  
						// If the left-hand side parts are equal, compare the right-hand side parts
						if (lhsParsed.right < rhsParsed.right) {
						  return -1; // Corrected to -1 for ascending order
						} else if (lhsParsed.right > rhsParsed.right) {
						  return 1; // Corrected to 1 for ascending order
						}
					} else if (event.direction === 'desc') {
					  if (lhsParsed.left > rhsParsed.left) {
						  return -1; // Changed to -1 for descending order
						} else if (lhsParsed.left < rhsParsed.left) {
						  return 1; // Changed to 1 for descending order
						}
					  
						// If the left-hand side parts are equal, compare the right-hand side parts
						if (lhsParsed.right > rhsParsed.right) {
						  return -1; // Changed to -1 for descending order
						} else if (lhsParsed.right < rhsParsed.right) {
						  return 1; // Changed to 1 for descending order
						}
					}
					return 0; // Both values are equal
				}
				 else {
				  if (event.direction === 'asc') {
					if (lhsValue < rhsValue) {
					  return -1;
					} else if (lhsValue > rhsValue) {
					  return 1;
					}
				  } else if (event.direction === 'desc') {
					if (lhsValue > rhsValue) {
					  return -1;
					} else if (lhsValue < rhsValue) {
					  return 1;
					}
				  }
				  return 0; // Both values are equal
				}
			  });
		}
		// Trigger table refresh
		table.renderRows();
		localStorage.setItem('pageSize', this.pageSize.toString());
	}
	  

	/**
 	 What color should the price-change arrow be
	*/
	// priceChangeColor(property) {
	// 	switch ( property.priceChange ) {
	// 		case -1:
	// 			return "red"
	// 			break
	// 		case 1: 
	// 			return "green"
	// 			break
	// 		case 0:
	// 			return null
	// 	}
	// }

	priceChangeColor(property) {

        return {

            'text-default':property?.priceChange==0||property?.priceChange==undefined||property?.priceChange==null? true:false,

            'red': property?.priceChange==-1?true:false,

            'green': property?.priceChange==1?true:false,

        };

    }

	/**
 	 Property listing detail page
	*/
	// onRowClick(property) {
	// 	this.router.navigate([`/property/${property.listingKey}`])
	// 	window.scrollTo(0,0);
	// }
	onRowClick(property) {
		// this.gaService.sendCtaClickEvent({
		// 	page_name: 'properties'},{
		// 	cta_type: 'button',
		// 	cta_location: 'properties_lists',
		// 	cta_description: `properties_list_item`,
		// 	property_id: `${property?.listingKey}`
		// });
		
		// Save the property object in localStorage as a JSON string
		localStorage.setItem('selectedProperty', JSON.stringify(property));
		
		// Navigate to the property detail page
		if(this.tourFlow_Id != null){
			this.router.navigate([`/property/${property.listingKey}`, { tourFlow_Id: this.tourFlow_Id }]);
		}else{
			this.router.navigate([`/property/${property.listingKey}`]);
		}
		// this.router.navigate([`/property/${property.listingKey}`]);
	
		// Scroll to the top of the page
		window.scrollTo(0, 0);
	}

	/**
 	 Which color class should be shown for this status?
	*/
	statusColorClass(property) {

		let key = property.mlsStatus
		return this.statusMap[key].color

	}

	/**
 	 Which display name should be shown for this status?
	*/
	statusDisplayName(property) {

		let key = property.mlsStatus
		return this.statusMap[key].display
	}

	/**
 	 Title text for hovering on the status
	*/
	statusTitle(property) {
		let key = property.mlsStatus
		return this.statusMap[key].title
	}

	/**
 	 Statuses, their display key and corresponding color
	*/
	statusMap = {
	
	// Green
		Active : { display: "A", color: "green", title: "Active" },

		// Orange
		ActiveUnderContract : { display: "AUC", color: "orange", title: "ActiveUnderContract" },
		"Active Under Contract" : { display: "AUC", color: "orange", title: "Active Under Contract" },

		"UCShortSale-Showing" : { display: "UC", color: "orange", title: "UCShortSale-Showing" },
		"ACTFirstRgt-Showing" : { display: "ACT", color: "orange", title: "ACTFirstRgt-Showing" },

		Pending : { display: "P", color: "orange", title: "Pending" },
		Contingent: { display: "C", color: "orange", title: "Contingent" },

		// Yellow
		ComingSoon: { display: "CS", color: "yellow", title: "ComingSoon" },
		"Coming Soon": { display: "CS", color: "yellow", title: "Coming Soon" },
		Leased: { display: "L", color: "yellow", title: "Leased" },
		// Black
		TemporarilyOffMarket : { display: "T", color: "black", title: "TemporarilyOffMarket"  },

		// Purple
		Withdrawn : { display: "W", color: "purple", title: "Withdrawn" },
		
		// Red
		Closed: { display: "S", color: "red", title: "Closed"},
		Sold : { display: "S", color: "red", title: "Sold" },

		// light-gray
		Expired : { display: "X", color: "light-gray", title: "Expired" },
		Delete : { display: "D", color: "light-gray", title: "Delete" },
		Hold : { display: "H", color: "light-gray", title: "Hold" },
		Incomplete : { display: "I", color: "light-gray", title: "Incomplete" },
		Cancelled : { display: "X", color: "light-gray", title: "Cancelled" }
	}

	onPageChange(event) {
		// if (event.pageSize !== this.pageSize) {
		// 	this.gaService.sendCtaClickEvent({
		// 	  page_name: 'properties'},{
		// 	  cta_type: 'selection',
		// 	  cta_location: 'select_items',
		// 	  cta_description: `${event.pageSize}`,
		// 	});
		//   } else {
		// 	const direction = event.pageIndex > this.currentPage ? 'next' : 'previous';
		
		// 	this.gaService.sendCtaClickEvent({
		// 	  page_name: 'properties'},{
		// 	  cta_type: 'icon',
		// 	  cta_location: 'properties_list',
		// 	  cta_description: `properties_arrow_${direction}`,
		// 	});
		//   }
		this.pageSize = event.pageSize;
		this.currentPage = Math.ceil(event.pageIndex + 1/event.pageSize);
		localStorage.setItem('pageSize', this.pageSize.toString());
		localStorage.setItem('currentPage', this.currentPage.toString());
		
		localStorage.setItem('pageIndex', event.pageIndex);
		let recordsPerPage = this.pageSize
		let totalRecords = this.properties.length;
		this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
		this.pageNumber = this.currentPage;
	}
	selectPage(page: string) {
		this.pageNumber = parseInt(page, 10) || 1;
		this.goToPage(this.pageNumber);
	}
	formatInput(event,input: HTMLInputElement) {
		if(!input.value.trim()){
			event.preventDefault();
			return;};
		let replaceValue = Number(input.value)>this.totalNumberOfPages?this.totalNumberOfPages.toString():input.value; 
		let page =replaceValue.replace(FILTER_PAG_REGEX, '');
		input.value=page;
	}
	goToPage(pageNumber: number) {
		let recordsPerPage = this.paginator.pageSize
		let totalRecords = this.paginator.length;
		this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
			// this updates the paginator component
		this.paginator.pageIndex = pageNumber - 1;
		// emit an event so that the table will refresh the data
		this.paginator.page.next({
			pageIndex: this.paginator.pageIndex,
			pageSize: this.paginator.pageSize,
			length: this.paginator.length
		});
	}

}
