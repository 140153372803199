import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '../client';
import { ToursService } from '../services/tours.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmListComponent } from '../confirm-list/confirm-list.component';
import { ClientService } from '../services/client.service';
import { bathroomCalculation, checkForAuthError } from '../utils'
import { ConfirmInputComponent } from '../confirm-input/confirm-input.component';
// import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent {

  @Input() client: any;

  @Input() timeframes: any;

  @Input() selectedTimeframe: number;

  @Input() title: string;

  @Input() orgList: any;

  @Input() docDetails: any;

  @Input() completedTours:any;

  showSpinner: boolean = true;

  @Output()
	buttonAction: EventEmitter<any> = new EventEmitter<any>()
  error: any;
	showCheck: boolean;

  constructor(private router: Router,
    private toursService: ToursService,
    private dialog: MatDialog,
    private clientService: ClientService,
	private snackBar: MatSnackBar,
	// private gaService:GoogleAnalyticsService
) {}

  ngOnInit() {
    // Simulate data loading or rendering tasks
    setTimeout(() => {
      this.showSpinner = false; // Set showSpinner to false to hide the spinner
    }, 1500); // Adjust the duration as needed
  }

  formatPhoneNumber(phoneNumber) {
    if (phoneNumber && phoneNumber.length === 10) {
        const formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        return formattedNumber;
    }
    return phoneNumber;
  }
  viewClients() {
	// this.gaService.sendCtaClickEvent({page_name:`client-${this.client?.id}`},{cta_type: 'label',cta_location:'view_client_details', cta_description:'view_all_clients'})
    this.router.navigate(['/clients']);
  }

  getTitle() {
    return `${this.client.firstName} ${this.client.lastName}`;
  }

  isValidImageUrl(url: string): boolean {
    const regex = /^(https:\/\/d3o40wwl2vh905\.cloudfront\.net\/assets\/images\/avatar\/clients\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
    return regex.test(url);
  }

  showTrailingProperties() {
    return this.client.mlsList != null;
  }

  onClick(event) {
       
		if (this.buttonAction != null) {
			// this.gaService.sendCtaClickEvent({page_name:`client-${this.client?.id}`},{cta_type: 'label', cta_location:'view_client_details', cta_description:'edit'});
			this.buttonAction.emit(event)
		}
	}

  onAddToTour(client) {
	//   this.gaService.sendCtaClickEvent({page_name:`client-${client?.id}`},{cta_type: 'button', cta_location:'view_client_details', cta_description:'add_to_tour', client_id:client?.id});//cta 55
	  this.confirmTour(client)
		
	}

  confirmTour(tourAdditions) {
		this.confirmFromTourList(tourAdditions)
	}

  confirmFromTourList (tourAdditions) {


		// Get the agent's tours
		this.toursService
			.fetchTours()
			.subscribe(tours => { 
		
				
				if ( tours["errors"] != null ) {
						this.error = tours["errors"][0].message
					return
				}  

				if (tours['data']['tours'].length === 0) {
					this.showCreateTourPopup(tourAdditions);
					return;
				}

				const filteredTours = tours['data']['tours'].filter((tour) => tour.tourCompleted === 0);

				// Have him choose which
				const dialogRef = this.dialog.open(ConfirmListComponent, {
					panelClass: 'clientPopUp',
					data: {
						message: 'Please select a tour',
						buttonText: {
							ok: 'SELECT',
							cancel: 'CANCEL',
							extra: 'CREATE NEW',
						},
						// list: tours["data"]["tours"].map( x => x.name ),
						list: filteredTours.map((x) => x.name),
						extraAction: () => {
							// An extra close call is probably not needed...
							dialogRef.close();
							this.showCreateTourPopup(tourAdditions);
						   },
					},
				})

				dialogRef
					.afterClosed()
					.subscribe((result) => {

					this.error = null

					this.showSpinner = true
					// if(result  && result?.cancel){
					// 	this.gaService.sendCtaClickEvent({page_name:`client`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'cancel'});//cta 59
					// }
					if (result && result.confirmed) {

						let tourData = tours["data"]["tours"].filter( x => { return x.name == result.selection })[0]
						// this.gaService.sendCtaClickEvent({page_name:`client`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'select', selection_option:tourData.name});//cta 57
						let clientIdList = tourAdditions != null ? [tourAdditions.id] : null;
						this.clientService
							.addClientsToTour(clientIdList, tourData.id)
							.subscribe(showingTour => { 
	
								this.showSpinner = false

								// Assume for alpha stage that an error means logged out
								if ( showingTour["errors"] != null ) {
									this.error = checkForAuthError( showingTour["errors"], this.router )
									return
								}

								this.navigateToTours();

						})
					} else {
						this.showSpinner = false
					}
				})

		})
	}

	showCheckmark(next) {


		this.showCheck = true
		setTimeout(() => {

			this.showCheck = false
			if ( next != null ) {
				next(this)
			}
		}, 3000)

	}

	showCreateTourPopup(tourAdditions) {
		// this.gaService.sendCtaClickEvent({page_name:`client`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'create_new'});//cta 58
		const inputDialogRef = this.dialog.open(ConfirmInputComponent, {
		  data: {
			message: 'Please name your new tour',
			currentRoute:`client-${this.client?.id}`,
			buttonText: {
			  ok: 'CREATE',
			  cancel: 'CANCEL',
			},
			inputs: [
			  { name: 'Tour Name', value: null },
			  { name: 'Tour Description', value: null },
			],
		  },
		});
	  
		let runAfterClosed = (result) => {
		  if (result?.confirmed) {
			this.showSpinner = true;
	  
			let clientIdList = tourAdditions != null ? [tourAdditions.id] : null;
	  
			// Create the new tour and get its id
			this.toursService.addTour(result.tour, null, clientIdList).subscribe((response) => {
			  // Rest of your existing code for creating the new tour...
			  if(response['errors'] != null) {
				this.error = response['errors'][0].message;
				// this.gaService.sendErrorDisplayEvent({page_name:'please_name_your_new_tour',message:this.error,type:"form_validation"})
				return;
			  }
			  this.navigateToTours();
			});
	  
			this.showSpinner = false;
			this.showCheckmark(null);
		  }
		};
	  
		runAfterClosed.bind(this);
	  
		inputDialogRef.afterClosed().subscribe(runAfterClosed);
	  }

	  navigateToTours() {
		this.router.navigate(['/tours']);
	}

	getOrganizationName(orgId: string): string | undefined  {
		if (!this.orgList) {
		  // console.error('orgList is undefined');
		  return 'Loading...';
		}
		const orgName = this.orgList.filter((orgList) => orgList.id == orgId);
		return orgName && orgName.length>0?orgName[0]['name']:'';
	}

	onDownloadLog(event:Event, dataItem:any) {
		if (!dataItem.file_path) {
		  event.preventDefault();
		  return;
		}
		const url = dataItem.file_path;
		const a = document.createElement('a');
		a.href = url;
		a.download = dataItem.file_path;
		document.body.appendChild(a);
		a.addEventListener('click', () => {
		  setTimeout(() => {
			this.snackBar.open('File Downloaded Successfully', 'Close', {
			  duration: 5000, // notification will automatically close after 5 seconds
			  horizontalPosition: 'center', // position the notification at the center of the screen
			  verticalPosition: 'top' // position the notification at the top of the screen
			});
			// this.renderer.removeChild(event.currentTarget, a);
		  }, 500); // Wait a short time to ensure download is initiated
		});
		a.click();
		document.body.removeChild(a);
	}

	viewTourSummary(tour){
		this.router.navigate([`tour-summary/${tour?.tourId}`])
	}


}
