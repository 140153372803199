import { Component, HostListener, OnInit, OnDestroy, EventEmitter, Output, Input, AfterViewInit, SimpleChanges, QueryList, ViewChildren, ViewChild } from '@angular/core';
// import { MatListModule } from '@angular/material/list';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatCardModule } from '@angular/material/card';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatSelectModule } from '@angular/material/select';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { MatTableModule } from '@angular/material/table';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
// import { MatNativeDateModule } from '@angular/material/core';
// import { MatChipsModule } from '@angular/material/chips';

import { Tour } from '../tour'
import { Showing, ShowingRequestStatus, ShowingRequestInterface, RequestActions } from '../showing'

import { ToursService } from '../services/tours.service'
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { LoginService } from '../services/login.service'
// import { ConfirmComponent } from '../confirm/confirm.component'
// import { ShowingCardComponent } from '../showing-card/showing-card.component'
import { Actions } from '../actions'
import { 
	// showingRequestStatus, 
	// formatTourDate, 
	// formatShowingDate, 
	isDateToday, 
	isDateThisWeek, 
	isDateThisMonth, 
	capitalized, 
	hasDatePassed, 
	dateFromEpochTime, 
	// epochTimeFromDate,
	// formControlValueFromDate,
	appointmentDate,
	formatDateT,
	formatDate,
	validateInput,
	checkForAuthError, 
	convertToValidFormat} from '../utils'

import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SearchBarComponent } from '@app/search-bar/search-bar.component';
import { MatPaginator } from '@angular/material/paginator';
const FILTER_PAG_REGEX='/^[0-9]/g';
export const MY_FORMATS = {
  parse: {
    dateInput:  ['l', 'LL'],
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
// import QRCode from 'qrcode'


export interface SortedTours {

	today: Tour[]
	thisWeek: Tour[]
	thisMonth: Tour[]
	later: Tour[]
	passed: Tour[]
}


@Component({
  selector: 'app-tour-list',
  templateUrl: './tour-list.component.html',
  styleUrls: ['./tour-list.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TourListComponent implements OnInit,OnDestroy {

	currentTourForModal: any;

	activeDropdownIndex: number | null = null;

	@HostListener('document:click', ['$event'])

	onDocumentClick(event: Event): void {
		const clickedElement = event.target as HTMLElement;
		const isClickInsideDropdown = clickedElement.closest('.dropList') || clickedElement.closest('.dropList-links');
	
		// If the click is outside any dropdown, close all dropdowns
		if (!isClickInsideDropdown) {
		  this.closeAllDropdowns();
		}
	  }


	// Add to scope
	capitalized = capitalized
	appointmentDate = appointmentDate
	isMobile: boolean;

	selectAllChecked = false
	showValidationError: boolean = false;
	showValidationEmailError: boolean = false;
	showValidationPhone:boolean = false
	showValidationEmail:boolean = false
	openModal:boolean = false

	// showValidationError: boolean = false;

	filterText: string = ""

	sortText: string = ""

	storedSortKey: string = ""

	today = new Date()

	showPasteError: boolean = false;

	nextYear = new Date(new Date().setFullYear(this.today.getFullYear() + 1))

	/**
 	 Use this track-by function to keep panel expansion states from changing on tour refresh
	*/
	trackByTourId = (index, pack) => this.tours[index]?.id;

	/**
 	 Used to create / destroy a polling timer for use in realtime status updates.
	*/
	private statusPollTimerId: ReturnType<typeof setInterval> = null

	sortedTours: SortedTours = {
		today: [],
		thisWeek: [],
		thisMonth: [],
		later: [],
		passed: []
	}

	/**
 	 The order in which we want to dispay the tours
	*/
	filteredToursInSequence = [
		{ 
			key: "today",
			display: "Today"
		},
		{ 
			key: "thisWeek",
			display: "This Week"
		},
		{
			key: "thisMonth",
			display: "This Month"
		},
		{
			key: "later",
			display: "Later"
		},	
		{
			key: "passed",
			display: "Passed"
		}
	]

	private _tours: Tour[]
	error: any;

	@Input() deletedTours: any[] = [];  // Receive the updated tours list
	

	
	@Input()
	set tours (tours: Tour[]) {

		this._tours = tours

		const storedAddedTour =(localStorage.getItem('AddedTour'));

		if(storedAddedTour){
			setTimeout(()=>{
				this.addShowingsTourId=null;
				this.panels.forEach(panel => panel.close());
			},20)
			localStorage.removeItem('AddedTour');
		}

		if(this.deletedTours?.length > 0){
			this.deletedTours.forEach((tourId) => {
				setTimeout(()=>{
					this.addShowingsTourId=null;
					this.panels.forEach(panel => panel.close());
				},20)
				this.removeMapForTour(tourId);
			});

			const storedpageNumber =Number(localStorage.getItem('pageNumber'));
			const storedrecordsPerPage =Number(localStorage.getItem('recordsPerPage'));
			const storedSortText =localStorage.getItem('sortKey2');

			if(storedSortText != this.sortText){
				localStorage.removeItem('pageNumber');
				localStorage.removeItem('recordsPerPage');
				localStorage.removeItem('sortKey2');
			}
			else{
				if(storedpageNumber && storedrecordsPerPage){
					// if(this.displayedData?.length > storedrecordsPerPage){
					// 	this.paginator.pageSize = storedrecordsPerPage
					// 	this.goToPage(storedpageNumber);
					// }
					if(storedpageNumber <= this.totalNumberOfPages){
						this.paginator.pageSize = storedrecordsPerPage
						this.goToPage(storedpageNumber);
					}
				}
			}
		}

		this.tours.forEach(tour=>{
			console.log("typeof tour.tourDate",tour.tourDate);

			// if (typeof tour.tourDate === 'string') {
			// 	tour.tourDate = new Date((tour.tourDate));
			// }

			if(validateInput(tour.tourDate)=="datetime"){
				// tour.tourDate = new Date(convertToValidFormat(tour.tourDate))
				tour.tourDate = convertToValidFormat(tour.tourDate);
			}
			else if(validateInput(tour.tourDate)=="numeric"){
				tour.tourDate = dateFromEpochTime((tour.tourDate.toString()))
			}

			// if (typeof tour.tourDate === 'string' && validateDateTime(tour.tourDate)) {
			// 	tour.tourDate = new Date((tour.tourDate));
			// }
			// if (validateNumericString(tour.tourDate)) {
			// 	tour.tourDate = dateFromEpochTime((tour.tourDate.toString()));
			// }
			tour.showings.forEach(showing =>{
				console.log("showing is-----------------",showing.showingTime)
				// if(showing.showingTime){
				// 	showing.showingTime = new Date(showing.showingTime);
				// }
				if(validateInput(showing.showingTime)=="datetime"){
					// showing.showingTime = new Date(showing.showingTime);
					// showing.showingTime = new Date(convertToValidFormat(showing.showingTime));
					showing.showingTime = convertToValidFormat(showing.showingTime);
				}
			})
		})

		this.sortedTours = {
			today: [],
			thisWeek: [],
			thisMonth: [],
			later: [],
			passed: []
		}

		this.sortTours()
		this._filteredTours = Object.assign({}, this.sortedTours);

		this.storedSortKey = localStorage.getItem('sortKey');
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
			  const currentUrl = event.urlAfterRedirects;
		
			  // Check if the current URL doesn't contain /properties or /property/propertyid
			  if (currentUrl.indexOf('/tours') === -1 && currentUrl.indexOf('/clients') === -1 && currentUrl.indexOf('/new-client') === -1 && currentUrl.indexOf('/properties') === -1 && currentUrl.indexOf('/property') === -1) {
				localStorage.removeItem('sortKey');
			  }
			}
		});

		if(this.storedSortKey){
			this.sortText = this.storedSortKey;
			this.dataFilter(this.sortText);
		}

		this.stopPolling()

		// Start a polling timer for the showing's statuses
		this.pollForUpdates()
		this.updateRoutes(this._tours)

	}
	get tours() {
		return this._tours
	}

	@Output()
	onRequestConfirmation: EventEmitter<Showing> = new EventEmitter<Showing>()

	@Output()
	onSetTourDate: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onSetShowingSchedule: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onDelete: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onDeleteTour: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onDeleteShowing: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onRemoveClient: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	updateRequest: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onCopy: EventEmitter<any> = new EventEmitter<any>()

	@Input()
	requestActions: RequestActions[]

	min_date : string;

	private _filteredTours: SortedTours
	private _tourIdMaintaining:string;


	get filteredTours() {
		return this._filteredTours 
	}
	set filteredTours(value) {
		this._filteredTours = value
	}

	private tourMaps: { [key: string]: mapboxgl.Map } = {};
	protected addShowingsTourId: string |null = null;
	protected showInvalidEmail: string[] = [];
	@ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
	@ViewChild('filterComponent') filterComponent: SearchBarComponent;

	pageIndex:number = 0;
	pageNumber:number=1;
	pageSize:number=15;
	currentPage:number=1;
	totalNumberOfPages:number=1;
	displayedData:any[]=[];
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	constructor(		
		private router: Router,
		private route: ActivatedRoute,
		private loginService: LoginService, 
		private toursService: ToursService,
		private http: HttpClient,
		// private dialog: MatDialog,
		private snackBar: MatSnackBar,
		// private gaService: GoogleAnalyticsService
		) { 

			// this.min_date = new Date().toISOString().split('T')[0];
			this.min_date = formatDate(new Date()).split(' ')[0];

			if ( this.loginService.authorization.token == null ) {
				this.router.navigate(['/login'])
			}
			window.addEventListener('beforeunload', () => {
				// Clear state when the user navigates away from the page
				window.history.replaceState({}, document.title, window.location.pathname);
			});
			this.isMobile = window.innerWidth < 768;

	}

	ngOnInit(): void {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const currentUrl = event.urlAfterRedirects;
		
				// Define the conditions for URLs that should NOT trigger the removal of localStorage items
				const isToursPage = currentUrl.indexOf('/tours') !== -1;
				const isPropertyPage = currentUrl.match(/^\/property\/\d+$/) !== null;
				const isPropertyWithTourFlowPage = currentUrl.match(/^\/property\/\d+;tourFlow_Id=\d+$/) !== null;
				const isPropertiesWithTourPage = currentUrl.match(/^\/properties\?tour=\d+$/) !== null;
				const isClientsWithTourPage = currentUrl.match(/^\/clients\?tour=\d+$/) !== null;
				const isNewClientWithTourPage = currentUrl.match(/^\/new-client\?tour=\d+$/) !== null;
				if (!isToursPage && !isPropertyPage && !isPropertiesWithTourPage && !isClientsWithTourPage && !isNewClientWithTourPage && !isPropertyWithTourFlowPage) {
					localStorage.removeItem('pageNumber');
					localStorage.removeItem('recordsPerPage');
					localStorage.removeItem('sortKey2');
					localStorage.removeItem('sizePage');
					localStorage.removeItem('AddedTour');
				}
			}
		});
		this.onResize();
		console.log("the tours we are gettinginitially",this.tours);
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		if(this.storedSortKey){
			this.sortText = this.storedSortKey;
			this.dataFilter(this.sortText);
		}
		else{
			this.dataFilter('all');
		}
		const navigation = history.state;
		if (navigation.data) {
			setTimeout(() => {
				const data = navigation.data; 
				this.addShowingsTourId=data?.tour;
				let newId = this.addShowingsTourId;
				console.log("this.addShowingsTourId",this.addShowingsTourId);
			}, 250);
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event?: Event): void {
	  this.isMobile = window.innerWidth < 768;
	}
	ngAfterViewInit(): void {
		const storedpageNumber =Number(localStorage.getItem('pageNumber'));
		const storedrecordsPerPage =Number(localStorage.getItem('recordsPerPage'));
		const storedSortText =localStorage.getItem('sortKey2');

		if(storedSortText != this.sortText){
			localStorage.removeItem('pageNumber');
			localStorage.removeItem('recordsPerPage');
			localStorage.removeItem('sortKey2');
		}
		else{
			if(storedpageNumber && storedrecordsPerPage){
				this.paginator.pageSize = storedrecordsPerPage
				this.goToPage(storedpageNumber);
			}
		}
		const navigation = history.state;
		if (navigation.data) {
		  	const data = navigation.data; 
			const openedTourData = this.tours.filter(tour=>tour.id===data.tour);
			setTimeout(() => {
				this.onHeaderClick(openedTourData.shift());
				localStorage.setItem('SizePage', this.pageSize.toString());
			}, 250);
		}
	}
	onPanelState(eventState:string,tour:any){
		//cta 38
		// this.gaService.sendCtaClickEvent({page_name:"tours"},{cta_type: 'list_item',
		// cta_location:`tour_list_${tour?.name?.trim().split(' ').join('_').toLowerCase()}`, 
		// cta_description:`${eventState}_view_details`
		// //property_ids:tour?.showings.map(item=>item?.property?.listingKey)//add properties that are under tour
		// })
	}
	onHeaderClick(tour:any){
		// event.stopPropagation();
		(mapboxgl as typeof mapboxgl).accessToken = environment.map.mapbox.apiKey;
		const mapToRemove = this.tourMaps[tour.id];
		if (mapToRemove) {
		  mapToRemove.remove(); // Remove the map associated with the tour ID
		  delete this.tourMaps[tour.id]; // Optionally, remove the map from the storage
		}	
		this.showMap(tour);
	}

	renderLinkIcon(node) {
		const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		const iconPath1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		const iconPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		const iconPath3 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		// iconSvg.textContent=`${count+1}`;
		iconSvg.setAttribute('display','block');
		iconSvg.setAttribute('height','41px');
		iconSvg.setAttribute('width','27px');
		iconSvg.setAttribute('fill', '#3FB1CE');
		iconSvg.setAttribute('viewBox', '0 0 27 41');
		iconPath.setAttribute('d','M13.5 40.05C19.299 40.05 24 37.6995 24 34.8C24 31.9005 19.299 29.55 13.5 29.55C7.70101 29.55 3 31.9005 3 34.8C3 37.6995 7.70101 40.05 13.5 40.05Z');
		iconPath.setAttribute('fill','black')
		iconPath.setAttribute('fill-opacity','0.2')
		iconPath1.setAttribute('d','M27 13.5C27 19.07 20.25 27 14.75 34.5C14.02 35.5 12.98 35.5 12.25 34.5C6.75 27 0 19.22 0 13.5C0 6.04 6.04 0 13.5 0C20.96 0 27 6.04 27 13.5Z')
		iconPath1.setAttribute('fill','#0F60A1');
		iconPath2.setAttribute('d','M13.5 19C16.5376 19 19 16.5376 19 13.5C19 10.4624 16.5376 8 13.5 8C10.4624 8 8 10.4624 8 13.5C8 16.5376 10.4624 19 13.5 19Z')
		iconPath2.setAttribute('fill','white');
		iconPath3.setAttribute('d','M13.5349 11L13.5 11.0274L13.4651 11V11.0548L11 13.0344V16H12.7023L12.7814 14.5583C12.9837 14.1624 13.3837 14.0727 13.5 14.0553C13.6163 14.0727 14.0163 14.1624 14.2186 14.5583L14.2977 16H16V13.0344L13.5349 11.0548V11Z')
		iconPath3.setAttribute('fill','#0F60A1');
		
		iconSvg.appendChild(iconPath);
		iconSvg.appendChild(iconPath1);
		iconSvg.appendChild(iconPath2);
		iconSvg.appendChild(iconPath3);
	  
		return node.appendChild(iconSvg);
	  }
	showMap(tour){
		const mapId = `map-${tour.id}`;
		  const map = new mapboxgl.Map({
			container: mapId,
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [0, 0],
			zoom: 1, 
			attributionControl: false,
		  });

		  this.tourMaps[tour.id] = map;
		  this.displayMap(map,tour);
	}
onFilterChange(filterValue:string){
	if(this.addShowingsTourId==null){
		this.panels.forEach(panel => panel.close());
	}
	else{
		// this.addShowingsTourId=null;
		setTimeout(()=>{
		this.addShowingsTourId=null;
		this.panels.forEach(panel => panel.close());
		},20)
		// setTimeout(()=>{
		// 	// this.addShowingsTourId=null;
		// 	const navigation = history.state;
		// 	if (navigation.data) {
		// 		const data = navigation.data; 
		// 		const openedTourData = this.tours.filter(tour=>tour.id===data.tour);
		// 		console.log("object",openedTourData);
		// 		this.onHeaderClick(openedTourData.shift());
		// 	}
		// },1000)}
	}
}
	dataFilterValue:any
	dataFilter(keyValue:string,event?:Event){
		this.sortText = keyValue;
		localStorage.setItem('sortKey', keyValue);
		if(keyValue=='all'){
			if(event?.type === 'click'){
				// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location: 'tour_details', cta_description:'all'});
			}
			// this.dataFilterValue= this.tours.sort((a, b)=> {
			this.displayedData= this.tours.sort((a, b)=> {
				const dateA = new Date(a.tourDate);
				const dateB = new Date(b.tourDate);
			  
				// // Compare only the date portion
				// const dateComparison = dateA.toDateString().localeCompare(dateB.toDateString());
			  
				// if (dateComparison !== 0) {
				//   return dateComparison;
				// }

				const dateComparison = dateA.getTime() - dateB.getTime();

            	return dateComparison;
			  
				// If dates are the same, sort by other criteria if needed
				// For example, you might want to sort by another property like otherProperty
				// return a.otherProperty.localeCompare(b.otherProperty);
			  });
			this.dataFilterValue=this.displayedData;
			this.pageNumber=1;
			this.pageIndex =  0;
			this.currentPage =  Math.ceil(this.pageIndex + 1/this.pageSize);
			let recordsPerPage = this.pageSize
				let totalRecords = this.displayedData.length;
				this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
			// this.selectedPoiIds = null;
			this.updateDisplayedData();
			// this.isDataLoaded = true;
			// this.showSpinner=false;
		}else{
			if(this.filterText){
				this.filterComponent.resetInput();
				this.filterText='';
			};
			console.log("",keyValue.replace(/\s/g, "").toLowerCase());
			let dat= keyValue.includes(' ')?keyValue.split(' ')[0].toLowerCase()+keyValue.split(' ')[1]:keyValue.toLowerCase();
			// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location: 'tour_details', cta_description:keyValue.trim().replace(' ','_').toLowerCase()});
			// this.dataFilterValue= this.filteredTours[dat];
			this.filteredTours[dat].sort((a, b) => {
				const dateA = new Date(a.tourDate);
				const dateB = new Date(b.tourDate);
				return dateA.getTime() - dateB.getTime();
			});
		
			this.displayedData = this.filteredTours[dat];
			this.dataFilterValue=this.displayedData;
			this.pageNumber=1;
			this.pageIndex =  0;
			this.currentPage =  Math.ceil(this.pageIndex + 1/this.pageSize);
			let recordsPerPage = this.pageSize
				let totalRecords = this.displayedData.length;
				this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
			// this.selectedPoiIds = null;
			this.updateDisplayedData();
			console.log("object",this.dataFilterValue,this.filteredTours[dat]);
		}
		// return data.reduce((accum, item) => {
	// 	if (item.projectItems && item.Active && item.projectItems.some(p => p.Active)) {
	// 	  accum.push(item);
	// 	}
	// 	return accum;
	//   }, []);
	}
	errorOutput(event){
		if(!event){
		//   alert("Please Enter Number Only");
		  this.showPasteError = true;
		}
	}

	displayMap(map: mapboxgl.Map,tour){
		const propertyCoordinates = [];
	
		  tour.showings.forEach((showing,index) => {
			const property = showing.property;
			if(property.address.latitude && property.address.longitude){
				const latitude = parseFloat(property.address.latitude);
				const longitude = parseFloat(property.address.longitude);
				const div = document.createElement('div');
				const divOuter = document.createElement('div');
				divOuter.classList.add('route-count-badge-conatiner');
				const span = document.createElement('div');
				span.classList.add('route-count-badge');
				span.innerText=`${index+1}`
				divOuter.appendChild(span);
				div.appendChild(divOuter);
				this.renderLinkIcon(div)
				const routeBadge:HTMLElement=div;
				// Create a marker for each property

				new mapboxgl.Marker(routeBadge)
				.setLngLat([longitude, latitude])
				.setPopup(new mapboxgl.Popup().setHTML(property.address.fullAddress))
				.addTo(map);
			    propertyCoordinates.push([longitude, latitude]);
			}
		  });

		  if (propertyCoordinates.length > 0) {
			const bounds = new mapboxgl.LngLatBounds();
			propertyCoordinates.forEach((coord) => {
			  bounds.extend(coord);
			});
			map.fitBounds(bounds, { padding: 50 });
			if(propertyCoordinates.length >=2){
				map.on('style.load', () => {
					this.drawRoute(map, propertyCoordinates);
				  });
			}
		  }
	}

	updateRoutes(tour:Tour[]){
		if(this._tourIdMaintaining){

		const storedpageNumber =Number(localStorage.getItem('pageNumber'));
		const storedrecordsPerPage =Number(localStorage.getItem('recordsPerPage'));
		const storedSortText =localStorage.getItem('sortKey2');

		if(storedSortText != this.sortText){
			localStorage.removeItem('pageNumber');
			localStorage.removeItem('recordsPerPage');
			localStorage.removeItem('sortKey2');
		}
		else{
			if(storedpageNumber && storedrecordsPerPage){
				// if(this.displayedData?.length > storedrecordsPerPage){
				// 	this.paginator.pageSize = storedrecordsPerPage
				// 	this.goToPage(storedpageNumber);
				// }
				if(storedpageNumber <= this.totalNumberOfPages){
					this.paginator.pageSize = storedrecordsPerPage
					this.goToPage(storedpageNumber);
				}
			}
		}
		const getIndex = tour.findIndex((item)=>item.id==Number(this._tourIdMaintaining));
		const propertyCoordinates = tour[getIndex].showings.map( (item) =>{
            return [
                 Number(item.property.address.longitude),
                 Number(item.property.address.latitude),
            ]
        })

		const mapInstance = this.tourMaps[tour[getIndex].id];
		this.removeMapForTour(tour[getIndex].id);
		this.showMap(tour[getIndex]);
		}
	}

	removeMapForTour(tourId) {
		const mapToRemove = this.tourMaps[tourId];
		if (mapToRemove) {
		  mapToRemove.remove(); 
		  delete this.tourMaps[tourId];
		}
	}

	drop(event: CdkDragDrop<[]>,tour:any) {

        moveItemInArray(tour.showings, event.previousIndex, event.currentIndex);

		const propertyCoordinates = tour.showings.map( (item) =>{
            return [
                 Number(item.property.address.longitude),
                 Number(item.property.address.latitude),
            ]
        })

		const propertyIds = tour.showings.map((item) => item.id);

		this.toursService
			.updateShowingOrder(propertyIds)
			.subscribe(response => {


	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )
	  				return
	  			}
		})

		const mapInstance = this.tourMaps[tour.id];

		if(propertyCoordinates.length >=1){
			this.drawRoute(mapInstance, propertyCoordinates);
			this.displayMap(mapInstance, tour);
		}

    }

	drawRoute(map: mapboxgl.Map, coordinates): void {
		const accessToken = environment.map.mapbox.apiKey;
		const coordinatesString = coordinates.map((coord) => `${coord[0]},${coord[1]}`).join(';');
		console.log("coordinates string are",coordinatesString);
		const apiUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesString}`;
		const params = {
		  access_token: accessToken,
		  geometries: 'geojson', // Get the route as GeoJSON
		  overview: 'full',
		};

		if (map.getSource('route') && map.getLayer('route')) {
			map.removeLayer('route');
			map.removeSource('route');
		}
	
		this.http.get(apiUrl, { params }).subscribe((response: any) => {
		  const route = response.routes[0].geometry;
		  console.log("route is",route);
	
		  // Add the route to the map as a polyline
		  map.addSource('route', {
			type: 'geojson',
			data: {
			  type: 'Feature',
			  properties: {},
			  geometry: route,
			},
		  });
	
		  map.addLayer({
			id: 'route',
			type: 'line',
			source: 'route',
			layout: {
			  'line-join': 'round',
			  'line-cap': 'round',
			},
			paint: {
				'line-color': '#2ac3f6', 
				'line-width': 6, 
				'line-opacity': 0.8,
				'line-offset':4
			  },
		  });
		});
	}

	// onPasteError(error: boolean) {
	// 	this.showPasteError = error;
	//   }

	onInputChanged() {
		this.showValidationError = false;
		this.showPasteError = false;
		this.showValidationPhone = false;
		this.showValidationEmail = false;
	}

	transform(value: string): string {
		if (!value) return '';
		const cleanedValue = value.replace(/\D/g, '');
		const formattedValue = cleanedValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
		return formattedValue;
	}

	onSubmitGenerateLink(tour,emailInputValue,phoneNumber) {
		const formPhoneNumber = phoneNumber.replace(/\D/g, '');
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		let emails =[];
		 // Validate each email
		 let inValidEmails = [];
		 if(emailInputValue){
			emails=  emailInputValue.split(',');
		 emails.forEach(email => {
			console.log("object",inValidEmails,emailPattern.test(email.trim()));
			 if (!emailPattern.test(email.trim())) {
				inValidEmails.push(email.trim());
				
			 }
		 });
		}
		 this.showInvalidEmail = inValidEmails;
		if (emailInputValue && (inValidEmails.length > 0) && formPhoneNumber) {
			if ((inValidEmails.length > 0) && formPhoneNumber && formPhoneNumber.length === 10) {
				// Invalid email, valid phone
				this.showValidationEmail = true;
				this.showValidationPhone = false;
				return
			} else if (!(inValidEmails.length > 0)&& formPhoneNumber && formPhoneNumber.length !== 10) {
				// Valid email, invalid phone
				this.showValidationEmail = false;
				this.showValidationPhone = true;
				return
			} else if (!(inValidEmails.length > 0) && formPhoneNumber && formPhoneNumber.length !== 10) {
				// Both email and phone are invalid
				this.showValidationError = true;
				return;
			}
		} else if ((inValidEmails.length > 0) && !formPhoneNumber) {
			// Check only email if email is provided
			if (!emailInputValue||(inValidEmails.length > 0)) {
				this.showValidationEmail = true;
				return;
			}
		} else if (formPhoneNumber && !(inValidEmails.length > 0)) {
			// Check only phone number if phone number is provided
			if (formPhoneNumber && formPhoneNumber.length !== 10) {
				this.showValidationPhone = true;
				return;
			}
		}

		// if(phoneNumber && phoneNumber.replace(/\D/g, '').length !== 10){
		// 	return;
		// }

		const formattedPhoneNumber = formPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

		if (!tour ||(!emailInputValue && !formattedPhoneNumber)) {
			// Show error message and prevent further processing
			this.showValidationError = true;
			return;
		 }
		 this.showValidationError = false;
		 this.showValidationPhone = false;
		 this.showValidationEmail = false;
		 this.showPasteError =false;
		 this.selectedTour=null;
        
		 let snackBarMessage = '';
		 if (!(inValidEmails.length > 0) && phoneNumber) {
			 snackBarMessage = "Custom Link is Emailed and sent to mobile";
		 } else if (!(inValidEmails.length > 0)) {
			 snackBarMessage = "Custom Link is emailed";
		 } else if (phoneNumber) {
			 snackBarMessage = "Custom Link is sent to mobile";
		 }

		// if (!this.isValidEmail(email)) {
		// 	this.showValidationEmail = true;
		// 	return;
		// }

		if(!tour)return;
		if((inValidEmails.length > 0))return;
		const user = this.loginService.getUser();
		// console.log("generateQRCode",tour,tour.id,user.agent.fullName);
		// With async/await
		const textUrl = `${window.location.protocol}//${window.location.host}/#/client-complete/welcome?tourId=${tour?.id}`;
        // window.open(textUrl,'_blank');
		let agentName= user?.agent?.fullName?user?.agent?.fullName:'';
		const capitalizeWords = sentence => sentence.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
		const capitalizedSentence = capitalizeWords(agentName);
		let emailData:string = emails.join(',');
		// return console.log("object",emailData,capitalizedSentence,textUrl);
		this.toursService.sendCustomerUrlEmail(emailData,textUrl,capitalizedSentence,tour.name,formattedPhoneNumber).subscribe({next:(response)=>{
			this.snackBar.open(snackBarMessage, 'Close', {
			  duration: 4000, // notification will automatically close after 5 seconds
			  horizontalPosition: 'center', // position the notification at the right corner of the screen
			  verticalPosition: 'top' // position the notification at the top of the screen
			  });
			  this.selectedTour=null;
			  this.onCloseModal();
		  },error:(err:any)=>{console.log("error",err);}})
	}
	// generateQRCode(tour:any,tourId:string){
    //  if(!tourId)return;
	//  console.log("generateQRCode",tour,tourId);
	//  // With async/await
	//  const text = `${window.location.host}/#/client-complete/welcome?tourId=${tourId}&clientsId=${tour?.clients[0]?.id}`;
	// //  const generateQR = async (text:string) => {
	// //    try {
	// // 	console.log("object",text);
	// // 	 let data = await QRCode.toDataURL(text);
	// // 	 console.log("test",data);
	// // 	//  QRDataUrl = data;
	// // 	 return data
	// //    } catch (err) {
	// // 	 console.error(err);
	// //    }
	// //  }
	// //  let QRDataUrl= await generateQR(text)
	//  console.log("QRCODE",tour,tourId,text);
	 
	// }


	isValidEmail(email: string): boolean {
		console.log("Email is",email);
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailRegex.test(email);
	}
	SendRemainderEmail(event,tour){
		event.stopPropagation();
		console.log("tour details are",tour);
		// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location:'view_tour_details', cta_description:'tour_details'});//cta 46
		this.toursService.webSendRemainderToursEmails(tour.id).subscribe({next:(response)=>{
			this.snackBar.open("Tour details has been emailed", 'Close', {
			  duration: 4000, // notification will automatically close after 5 seconds
			  horizontalPosition: 'center', // position the notification at the right corner of the screen
			  verticalPosition: 'top' // position the notification at the top of the screen
			  });
		  },error:(err:any)=>{
			console.log("error",err);
			this.snackBar.open("There was an error while sending tour details", 'Close', {
				duration: 4000, // notification will automatically close after 5 seconds
				horizontalPosition: 'center', // position the notification at the right corner of the screen
				verticalPosition: 'top' // position the notification at the top of the screen
				});
		}})
	}


	ngOnDestroy(): void {

		this.stopPolling()
	}


	stopPolling() {
		
		// Stop the polling timer
		clearInterval(this.statusPollTimerId)
	}
 
	/**
 	 Receive changes for the filter text
	*/
	filterChange(searchText) {
		setTimeout(()=>{
			this.addShowingsTourId=null;
			this.panels.forEach(panel => panel.close());
		},20)
		this.filterText = searchText

		// Run the filter
		this.runFilter()

	}
    showTourSummaryModal:boolean=false;
	selectedTour:Tour
	selectedClients:{email:string,phone:string,name?:string}
	// onOpenModal(tour){
	//   console.log("tour is",tour);
	//   this.selectedTour=tour;
	//   const emailString =tour.clients.map(client =>client.email).join(",");
	//   const phoneString =tour.clients[0].phone//.map(client =>client.phone).join(",");
	//   this.selectedClients={email:emailString,phone:phoneString};
    //   this.showTourSummaryModal=true;
	//   this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location:'view_tour_details', cta_description:'livepad_mobile_link', property_ids: tour?.showings.map(item=>item?.property?.listingKey)});//cta 45
	// }

	onOpenModal(tour){
		console.log("tour is",tour);
		const clientIds = tour.clients.map(client => client.id);
		this.toursService
			.checkDocApprovedByClients(clientIds)
			.subscribe(response => {
				if(response['data']['checkDocApprovedByClients'].message === 'true'){
					this.selectedTour=tour;
					const emailString =tour.clients.map(client =>client.email).join(",");
					const phoneString =tour.clients[0].phone//.map(client =>client.phone).join(",");
					this.selectedClients={email:emailString,phone:phoneString};
					this.showTourSummaryModal=true;
					this.openModal = false;
					// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location:'view_tour_details', cta_description:'livepad_mobile_link', property_ids: tour?.showings.map(item=>item?.property?.listingKey)});//cta 45
				}else{
					this.openModal = true;
					this.currentTourForModal = tour;
					this.showTourSummaryModal=false;
				}
	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
					this.error = checkForAuthError( response["errors"], this.router )
	  				return
	  			}
		})
	}

	showAllClients:boolean=false;
	onOpenModa2(event,tour){
	  event.stopPropagation();
	  this.selectedTour=tour;
	  console.log("tour is----------",tour);
      this.showAllClients=true;
	}

	navigateToClients(tourId: any) {
		// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location:'view_tour_details', cta_description:'add_clients'});
		this.router.navigate(['../clients'], { queryParams: { tour: tourId } });
	}

	onCloseModal(){
		console.log("this method calls");
      this.showTourSummaryModal=false;
	  this.openModal = false;
	  this.showValidationError = false;
	  this.showPasteError = false;
	  this.showValidationPhone = false;
	  this.showValidationEmail = false;
	  this.selectedTour=null;
	  this.showAllClients=false;
	  this.selectedTour = null;
	}
	viewTourSummary(tour){
		// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location:'view_tour_details', cta_description:'tour_summary', tour_id:tour?.id, property_ids: tour?.showings.map(item=>item?.property?.listingKey)})//cta 77
		this.router.navigate([`tour-summary/${tour.id}`])
	}
	/**
 	 Format a date into YYYY-MM-DD.
 	 Used in the min/max values for our tour-date input
	*/
	minMaxDateFormat(date) {
		return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
	}

	/**
 	 Filter tours by search text
	*/
	private runFilter () {

		let filterFn = (x) => {

			let passName = 
				x.name
					.toLowerCase()
					.includes( this.filterText.toLowerCase() )

			let passDesc = 
				x.description?.toLowerCase()
					.includes( this.filterText.toLowerCase() ) 

			return passName || passDesc
		}


		// let ret = {
		// 	today: this.sortedTours.today.filter( filterFn ),
		// 	thisWeek: this.sortedTours.thisWeek.filter( filterFn ),
		// 	thisMonth: this.sortedTours.thisMonth.filter( filterFn ),
		// 	later: this.sortedTours.later.filter( filterFn ),
		// 	passed: this.sortedTours.passed.filter( filterFn )
		// }

		let ret1 = [
			 ...this.sortedTours.today.filter( filterFn ),
			...this.sortedTours.thisWeek.filter( filterFn ),
			...this.sortedTours.thisMonth.filter( filterFn ),
			...this.sortedTours.later.filter( filterFn ),
			...this.sortedTours.passed.filter( filterFn )
		]

		// this._filteredTours = ret

		this.displayedData = Array.from(new Set(ret1));
		this.dataFilterValue = this.displayedData;
		this.pageNumber=1;
		this.pageIndex =  0;
		this.currentPage =  Math.ceil(this.pageIndex + 1/this.pageSize);
		let recordsPerPage = this.pageSize
		let totalRecords = this.displayedData.length;
		this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
		// this.selectedPoiIds = null;
		this.updateDisplayedData();
		// this.isDataLoaded = true;
		// this.showSpinner=false;
		this.sortText = 'all';
		// this.dataFilter("all");
	}

	/**
 	 Section colors by date. 
	*/
	sectionStyle(sectionName) {

		let colors = {
			today: { 
				"color" : "#0240d5",
				"font-weight": "800",
			    "font-size": "24px"
			},
			thisWeek: { 
				"color" : "#2f61d8",
				"font-weight": "700",
			    "font-size": "20px"
			},
			thisMonth: { 
				"color" : "#5278d4",
				"font-weight": "600",
			    "font-size": "18px"
			},
			later: {
				"color" : "#7591d6",
				"font-weight": "500",
			    "font-size": "16px"
			},
			passed: { 
				"color" : "#ab1212",
				"font-weight": "500",
			    "font-size": "16px"	
			}
		}

		return colors[sectionName]
	}

	/**
 	 Sort tours into time-based categories:
 	 - Today
 	 - This week
 	 - This month
 	 - Later
 	 - Earlier
	*/
	sortTours() {

		let today = new Date()
		for ( var i in this.tours ) {

			let tour = this.tours[i]
			// let tourDate = dateFromEpochTime(`${tour.tourDate}`)
			let tourDate = tour.tourDate


			if ( hasDatePassed(tourDate) ) {
				this.sortedTours.passed.push(tour)
				continue
			}

			if ( isDateToday(tourDate) ) {
				this.sortedTours.today.push(tour)
				// continue
			}

			if ( isDateThisWeek(tourDate) ) {
				this.sortedTours.thisWeek.push(tour)
				// continue
			}

			if ( isDateThisMonth(tourDate) ) {
				this.sortedTours.thisMonth.push(tour)
				// continue
			}

			this.sortedTours.later.push(tour)
		}

	}
	
	onCopyTours() {

		if ( this.onCopy != null ) {
			console.log("this.selectedTours()",this.selectedTours());
			this.onCopy.emit( this.selectedTours() )
		}

	}


	/**
 	 Relay to emitter
	*/
	onDeleteTours() {

		if ( this.onDelete != null ) {
			// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location: 'my_tours', cta_description:'delete'});
			this.onDelete.emit( this.selectedTours() )
		}

	}

	DeleteTours(event,tour: any) {
		event.stopPropagation();
		if (this.onDeleteTour != null) {
			// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location: `tour_list_${tour?.name.trim().split(' ').join('_').toLowerCase()}`, cta_description:'delete'});
			this.onDeleteTour.emit([tour]);
		}
		// if (this.onDelete != null) {
		//   this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location: `tour_list_${tour?.name.replace(' ','_').toLowerCase()}`, cta_description:'delete'});
		//   this.onDelete.emit(tour);
		// }
	}

	deleteShowing(eventData) {
		this._tourIdMaintaining=eventData.tour.id;
		this.onDeleteShowing?.emit( eventData )
		// const propertyCoordinates = eventData.showings.map( (item) =>{
        //     return [
        //          Number(item.property.address.longitude),
        //          Number(item.property.address.latitude),
        //     ]
        // })

		// const mapInstance = this.tourMaps[tour.id];

		// if(propertyCoordinates.length >=1){
		// 	this.drawRoute(mapInstance, propertyCoordinates);
		// }
		
	}

	/**
 	 Tiny `x` overlay to remove a client from a tour
	*/
	// removeClient(event, client, tour) {
	// 	event.stopPropagation()
	// 	this.onRemoveClient?.emit({ client, tour })
	// }

	removeClient(event, client, tour) {
		event.stopPropagation();
		this.onRemoveClient?.emit({ client, tour });
		if (this.selectedTour?.clients.length === 1) {
		  this.showAllClients = false; 
		  this.selectedTour = null;
		}
	}

	/**
 	 Gather selected
	*/	
	selectedTours() {

		return this.tours.filter( x => { return x.checked == true })
		
	}

	/**
 	 Select all tours checkbox
	*/
	onSelectAllTours() {

		this.selectAllChecked = this.selectAllChecked == true ? false : true


	    this.tours.forEach(each => { 

	    	each.checked = this.selectAllChecked 
	    })
	}


	/**
 	 Selects a single tour checkbox
	*/
	// onSelectOneTour(tour,tourId, event ) {

	//     // tour.checked = tour.checked == true ? false : true 
	// 	this.dataFilterValue.filter(tour =>tour.id==tourId? tour.checked=true:tour.checked=false);
	// 	// event.stopPropagation();
	// }

	onSelectOneTour(newTour, event) {
		// Find the tour in dataFilterValue by its ID
		const tour = this.dataFilterValue.find(t => t.id === newTour.id);
		if (tour) {
		  // Toggle the checked property
		  tour.checked = !tour.checked;
		}
		// If you need to handle any other logic, you can do it here
		// event.stopPropagation(); // Uncomment if you need to stop event propagation
	}


	/**
 	 Html click receivers
	*/
	onRequested(showing) {

		let len = showing.showingConfirmationRequest.length

		// Hasn't submitted a request yet
		if ( showing.showingConfirmationRequest.length == 0 ) {
			this.onRequestConfirmation.emit(showing)
			return

		// Listing agent countered
		} 

		let latestRequest = showing.showingConfirmationRequest[len-1]

		if ( latestRequest.requestActions.code == Actions.ListingAgentConfirmRequest.code || latestRequest.counteredTime ) {

			this.navigateToConfirmationPage(latestRequest.confirmationToken)

		// Any other status brings you to the showing summary page
		} else {

			this.navigateToConfirmationListPage(showing.id)
		}

	}

	/**
 	 For viewing a countered request, this will take you the latest
 	 confirmation request page for this showing.
	*/
	navigateToConfirmationPage(confirmationToken) {
        const url = `/#/confirmation-request/${confirmationToken}`;
        window.open(url, '_blank');
    }

	/**
 	 When the button for requesting a confirmation is clicked but has already been submitted,
 	 we move them to the confirmation dialogue page
	*/
	navigateToConfirmationListPage(showingId) {
		this.router.navigate([`/showing/requests/${showingId}`], { queryParams : this.route.snapshot.queryParams })		
	}

	/**
 	 Update the tour and emit the event
	*/
	onUpdateTourDate(tour:any, event: MatDatepickerInputEvent<Date>,tourDate:string|Date) {
		console.log("tour is",tour)
		const newDate = `${event.value}`;
	  
		// Validate the new date format
		if (!newDate || newDate === '') {
		  tour.dateError = 'Invalid date: Date cannot be empty.';
		  return;
		}
		let strInputValue = newDate.replace(/-/, '/').replace(/-/, '/');
	  
		// const selectedDate = new Date(strInputValue);
		const selectedDate = new Date(newDate);
		// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location: 'view_tour_details', cta_description:'calender_icon',selection_option:selectedDate.toString(),tour_id:tour?.id});//cta 47

		let time = new Date();

		selectedDate.setHours(time.getHours())

		selectedDate.setMinutes(time.getMinutes())

		selectedDate.setSeconds(0);

		const formattedData = formatDate(selectedDate);
	  
		// Validate if the selected date is a valid date
		if (isNaN(selectedDate.getTime())) {
		  tour.dateError = 'Invalid date: Please select a valid date.';
		  return;
		}
	  
		// Clear the error message if the date is valid
		tour.dateError = '';
	  
		// Emit an event to notify the parent component about the updated tour date
		this.onSetTourDate.emit({ tour, newDate: formattedData, oldDate: formatDate(tour.tourDate) });

		// Update the tour date
		tour.tourDate = formatDateT(selectedDate);
	  }
	  

	onUpdateShowingTime(eventData) {
		this.onSetShowingSchedule.emit( eventData )
	}	


	/**
 	 Jump to client
	*/
	onClientChipClick(clientId) {

		this.router.navigate(['client', clientId])

	}
	onShowClientEvent(event:Event,client,tourName:string){//cta 39
		event.stopPropagation();
		// this.gaService.sendCtaClickEvent({page_name:'tours'},{cta_type: 'button', cta_location: `tour_list_${tourName.replace(' ','_').toLowerCase()}`, cta_description:`${client.firstName}_${client.lastName}`.replace(' ','_').toLowerCase()})
	}

	/**
 	 Fetch showing statuses
	*/
	pollForUpdates() {

		let self = this
		this.statusPollTimerId = setInterval( (self) => {

			if ( self.tours.length < 1 ) {
				return
			}


			if ( Array.prototype['last'] == undefined ) {
				Object.defineProperty(Array.prototype, 'last', {
					value: function() {
						if ( this.length == 0 ) { return null }
						return this[this.length-1]
					}
				})
			}

			// Uses log in token for choosing which tours to return
			self.toursService
				.pollToursStatus()
				.subscribe(response => { 
			
					// Errors in this can quietly die
					if ( response["errors"] != null ) {

						checkForAuthError( response["errors"], this.router )

						return
					}  

					// A new tour has been added and they all must be refreshed instead of a simple update
					if ( this.tours.length != response["data"].pollToursStatus.length ) {

						this.stopPolling()

						// Request parent to update
						this.updateRequest?.emit()

					}

					// Update tour data
					for ( var i in this.tours ) {
						
						let tour = this.tours[i]
						let update = null

						// Find matching id and break
						for ( var j in response["data"].pollToursStatus ) {
							
							let tourUpdate = response["data"].pollToursStatus[j]
							if ( tourUpdate.id == tour.id ) {

								update = response["data"].pollToursStatus.splice(j,1).last()
								break;
							}

						}

						// tour.tourDate = dateFromEpochTime(update.tourDate)
						// tour.clients = update.clients
						
						// Update showing data
						for ( var j in tour.showings ) {

							let showing = tour.showings[j]
							let showingUpdate = null

							for ( var k in update.showings ) {

								let updatedShowing = update.showings[k]
								if ( showing.id == updatedShowing.id ) {

									showingUpdate = update.showings.splice(k, 1).last()
									break

								}

							}

							if ( showingUpdate != null ) {

								if(validateInput(showingUpdate.showingTime)=="datetime"){
									// tour.showings[j].showingTime = new Date((showingUpdate.showingTime));
									tour.showings[j].showingTime = convertToValidFormat(showingUpdate.showingTime);
								}
								else if(validateInput(showingUpdate.showingTime)=="numeric"){
									tour.showings[j].showingTime = dateFromEpochTime(showingUpdate.showingTime)
								}

								// if ( showingUpdate.showingTime != null ) {
								// 	tour.showings[j].showingTime = new Date(showingUpdate.showingTime)
								// }
								tour.showings[j].showingConfirmationRequest = showingUpdate.showingConfirmationRequest
							}
						}

						// Set back on scope
						this.tours[i] = tour
					}


					

				})

			}, 3000, self)
	}
addShowingsEvent(event:Event,tour){//cta 40
	event.stopPropagation();
	// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location: `view_tour_details`, cta_description:'add_showing'});//${tour?.name.replace(' ','_').toLowerCase()}`
}

updateDisplayedData() {
	
    // Calculate starting and ending index of the current page
    const startIndex = (this.currentPage-1) * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.displayedData.length);
    
    // Get data for the current page
    this.dataFilterValue = this.displayedData.slice(startIndex, endIndex);
  }
  onPageChange(event) {
	const deletedShowing =localStorage.getItem('deletedShowing');
    const storedpageNumber =Number(localStorage.getItem('pageNumber'));
	const storedrecordsPerPage =Number(localStorage.getItem('recordsPerPage'));
    const storedSizeOfPage = Number(localStorage.getItem('SizePage'))

	if( deletedShowing == null && storedSizeOfPage != event.pageSize || storedpageNumber != Math.ceil(event.pageIndex + 1/event.pageSize)){
		setTimeout(()=> {
			this.addShowingsTourId=null;
			this.panels.forEach(panel => panel.close());
		},20)
	}

	localStorage.removeItem('deletedShowing')

	if(storedpageNumber && storedrecordsPerPage){
		localStorage.removeItem('pageNumber');
		localStorage.removeItem('recordsPerPage');
		localStorage.removeItem('sortKey2');
	}
    this.pageSize = event.pageSize;
    this.pageIndex =  event.pageIndex;
    this.currentPage =  Math.ceil(event.pageIndex + 1/event.pageSize);
	let recordsPerPage = this.pageSize
	let totalRecords = this.displayedData.length;
	this.pageNumber = this.currentPage;
	this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
    this.updateDisplayedData()
	localStorage.setItem('pageNumber', this.pageNumber.toString());
	localStorage.setItem('recordsPerPage', recordsPerPage.toString());
	localStorage.setItem('sortKey2', this.sortText);
	// localStorage.setItem('currentPage', this.currentPage.toString());
	}
  selectPage(page: string) {
		this.pageNumber = parseInt(page, 10) || 1;
		this.goToPage(this.pageNumber);
	}
	formatInput(event,input: HTMLInputElement) {
		if(!input.value.trim()){
			event.preventDefault();
			return;};
		let replaceValue = Number(input.value)>this.totalNumberOfPages?this.totalNumberOfPages.toString():input.value; 
		let page =replaceValue.replace(FILTER_PAG_REGEX, '');
		input.value=page;
	}
	goToPage(pageNumber: number) {
		let recordsPerPage = this.paginator.pageSize
		let totalRecords = this.paginator.length;
		this.totalNumberOfPages = Math.ceil(totalRecords / recordsPerPage);
			// this updates the paginator component
		this.paginator.pageIndex = pageNumber - 1;
		// emit an event so that the table will refresh the data
		this.paginator.page.next({
			pageIndex: this.paginator.pageIndex,
			pageSize: this.paginator.pageSize,
			length: this.paginator.length
		});
    	this.updateDisplayedData()
	}
ngDestroy(){
	this.addShowingsTourId=null;
}

navigateToMyDocument() {
    this.router.navigate(['/my-document']);
}

navigateToMyDocument1(tour) {
	this.selectedTour=tour;
	const emailString =tour.clients.map(client =>client.email).join(",");
	const phoneString =tour.clients[0].phone//.map(client =>client.phone).join(",");
	this.selectedClients={email:emailString,phone:phoneString};
	this.showTourSummaryModal=true;
	this.openModal = false;
	// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'button', cta_location:'view_tour_details', cta_description:'livepad_mobile_link', property_ids: tour?.showings.map(item=>item?.property?.listingKey)});//cta 45
    // this.router.navigate(['/my-document']);
}

toggleDropdown(index: number): void {
    this.activeDropdownIndex = this.activeDropdownIndex === index ? null : index;
}

closeAllDropdowns(): void {
    this.activeDropdownIndex = null;
}
}
