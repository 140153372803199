<div *ngIf="!isMobile" class="cursor p-1 me-2 drag-icon-sm" cdkDragHandle [ngClass]="{'tour-complete-disabled': tour?.tourCompleted == 1}">
    <div class="menu-bar"></div>
    <div class="menu-bar"></div>
    <div class="menu-bar"></div>
    <div class="menu-bar"></div>
</div>
<a routerLink="../property/{{showing.property.listingKey}}" (click)="onImgClickEvent($event,showing?.property?.listingKey,tour)" class="prop-image-container"> 
<div class="property-serial-number">
    {{propertyIndex}}
</div>
	<img class="photo-showing-thumb" src="{{ firstOf(showing.property.propertyPhotos)?.url }}" onerror="this.src='./assets/no_photo_455x340.jpg'" alt="User Picture" />
</a>
<div [ngClass]="(showing.showingConfirmationRequest.length > 0)?'cell-container request-sent':'cell-container'">
	<div class="divided divided-address" [ngClass]="{'tour-complete-disabled': tour?.tourCompleted == 1}">
		<!-- <div width="200px">{{ addressStreet(showing.property.address.fullAddress) }}</div>
		<div width="200px">{{ addressCity(showing.property.address.fullAddress) }}</div> -->
		<!-- <div width="200px">
			{{ 
			  showing.property?.address.streetNumber && showing.property?.address.streetName 
				? showing.property?.address.streetNumber + ' ' + showing.property?.address.streetName + ', ' 
				: showing.property?.address.streetNumber && !showing.property?.address.streetName 
				  ? 'N/A, ' 
				  : showing.property?.address.streetName && showing.property?.address.streetName !== "null" && showing.property?.address.streetName !== "none" 
					? showing.property?.address.streetName + ', ' 
					: 'N/A, ' 
			}}
		  </div>
		  <div width="200px">
			{{ 
			  (showing.property?.address?.city || '') + 
			  (showing.property?.address?.state ? ', ' + showing.property?.address?.state : '') + 
			  (showing.property?.address?.zip ? ', ' + showing.property?.address?.zip : '') 
			}}
		  </div> -->
		<div width="200px">{{ addressStreet(showing?.property?.UnparsedAddress) }}</div>
        <div width="200px">{{ addressCity(showing?.property?.UnparsedAddress) }}</div>
	</div>
    <!-- <input class="divided date-picker" type="time" [disabled]="showing.showingConfirmationRequest.length > 0" [value]="showing.showingTime | date:'HH:mm' " 
		(change)="onUpdateShowingTime(showing, tour, $event)" id="datePicker" aria-labelledby="datePicker"> -->
		<div class="input-group input-group-sm mb-3 timepicker-input-width" aria-label="time picker" [ngClass]="{'tour-complete-disabled': tour?.tourCompleted == 1}">
			<!-- <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Time" class="form-control"
			[attr.disabled]="showing.showingConfirmationRequest.length > 0" [value]="showing.showingTime"
			(dateTimeChange)="onUpdateShowingTime(showing, tour, $event)" id="timePicker" aria-labelledby="timePicker"
			[readonly]="showing.showingConfirmationRequest?.[0]?.confirmed?true:false"
			[disabled]="showing.showingConfirmationRequest?.[0]?.confirmed?true:false"
			> -->
			<input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Time" class="form-control"
			[attr.disabled]="showing.showingConfirmationRequest.length > 0" [value]="showing.showingTime"
			(dateTimeChange)="onUpdateShowingTime(showing, tour, $event)" id="timePicker" aria-labelledby="timePicker"
			[readonly]="showing.showingConfirmationRequest.length > 0"
			[disabled]="showing.showingConfirmationRequest.length > 0"
			>
			<span class="input-group-text bg-white timer-img" [owlDateTimeTrigger]="dt1" *ngIf="!(showing.showingConfirmationRequest.length > 0)">
				<img src="assets/timer.png" alt="Timer" matSuffix width="20" />
			</span>
			<!-- <owl-date-time [pickerType]="'timer'" [hour12Timer] = "'true'"  #dt1
			[disabled]="showing.showingConfirmationRequest?.[0]?.confirmed?true:false"
			></owl-date-time> -->
			<owl-date-time [pickerType]="'timer'" [hour12Timer] = "'true'"  #dt1
			[disabled]="showing.showingConfirmationRequest.length > 0"
			></owl-date-time>
		</div>
	<div class="divided">
		{{ showing.instructions }}
	</div>
</div>
<div class="flex-container card-bottom">
	<div class="cursor p-1 me-2 mobile-drag-icon-sm" *ngIf="isMobile" cdkDragHandle cdkDrag [ngClass]="{'tour-complete-disabled': tour?.tourCompleted == 1}">
		<div class="menu-bar"></div>
		<div class="menu-bar"></div>
		<div class="menu-bar"></div>
		<div class="menu-bar"></div>
	</div>
	<div class="button-with-label" [ngClass]="{'tour-complete-disabled': tour?.tourCompleted == 1}">
		<div class="button-container margin-bottom-8" (click)="onWrapperClicked(showing,tour)">
			<!-- <button mat-mini-fab [color]="confirmedStyle(showing, datePickerValue).color" [style]="confirmedStyle(showing, datePickerValue).style" aria-label="Has the showing been confirmed with the listing agent?">
			  <mat-icon matSuffix>{{confirmedStyle(showing, datePickerValue).icon}}</mat-icon>
			</button> -->
			<!-- <span>
		        <img src="assets/icon-park-solid_appointment.png" alt="Delete" matSuffix width="24" />
			</span> -->
			<span>
		        <img [src]="confirmedStyle(showing, datePickerValue).imgSrc" matSuffix width="24" alt="icons" />
			</span>
		</div>
		<mat-label class="divided">{{confirmedStyle(showing, datePickerValue).title}}</mat-label>
	</div>
	<div class="button-with-label" [ngClass]="{'tour-complete-disabled': tour?.tourCompleted == 1}">

    	<div class="button-container margin-bottom-8">
			<!-- <button mat-mini-fab color="warn" aria-label="Delete this showing" (click)="deleteShowing(showing, tour)">
		        <mat-icon>delete_forever</mat-icon>
			</button> -->
			<span (click)="deleteShowing(showing, tour)">
		        <img src="assets/bxs_trash.png" alt="Delete" matSuffix width="24" />
			</span>
		</div>
		<mat-label  class="divided">Delete</mat-label>
	</div>
</div>
<mat-error>{{error}}</mat-error>
