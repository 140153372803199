import { Directive, ElementRef, HostListener, Optional, Self } from '@angular/core';

@Directive({
  selector: '[appFormPhone]'
})
export class FormPhoneDirective {

  private regex: RegExp = new RegExp('^[0-9]*$');

  constructor(private elementRef: ElementRef) {}
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const cursorPosition = this.elementRef.nativeElement.selectionStart;
    const currentValue = this.elementRef.nativeElement.value;

    // Allow navigation keys: backspace, delete, arrow keys, home, end
    if (['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Home', 'End'].indexOf(event.key) !== -1) {
      return; // Allow default behavior
    }

    // Prevent input if it doesn't match the regex or if trying to start with '0'
    if (!this.regex.test(event.key) || 
        (cursorPosition === 0 && event.key === '0') || 
        (currentValue.replace(/\D/g, '').length >= 10)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    this.formatInput();
  }

  @HostListener('ngModelChange', ['$event']) onInputChange(event: string) {
    if (event) {
      this.formatInput();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      const regEx = new RegExp('^[0-9]*$');
      if (!regEx.test(clipboardData)) {
        event.preventDefault();
      }
    }
  }

  private formatInput() {
    const element = this.elementRef.nativeElement;
    let inputVal = element.value;
    const cursorPosition = element.selectionStart;
    const oldValue = inputVal;

    // Remove all non-digit characters
    inputVal = inputVal.replace(/\D/g, '');

    // Ensure the input doesn't start with '0' if not empty
    if (inputVal.length > 0 && inputVal.startsWith('0')) {
      // inputVal = inputVal.substring(1);  // Remove the leading zero
      event.preventDefault();
    }

    // Limit input to 10 digits
    if (inputVal.length > 10) {
      inputVal = inputVal.substring(0, 10);
    }

    // Apply formatting if input is present
    if (inputVal) {
      inputVal = inputVal.replace(/(\d{3})(\d{3})(\d{0,4})/, (match, p1, p2, p3) => {
        if (p3) {
          return `${p1}-${p2}-${p3}`;
        } else if (p2) {
          return `${p1}-${p2}`;
        } else {
          return p1;
        }
      });

      // Set the formatted value back to the input field
      element.value = inputVal;

      const formattedValue = element.value;
      let newCursorPosition = cursorPosition;

      if (oldValue.length > formattedValue.length) {
        // Handling deletion: Move cursor back
        newCursorPosition = cursorPosition - (oldValue.length - formattedValue.length);
      } else if (oldValue.length < formattedValue.length) {
        // Handling insertion: Move cursor forward
        newCursorPosition = cursorPosition + (formattedValue.length - oldValue.length);
      }

      // Adjust cursor position based on non-numeric characters
      const adjustedPosition = this.getAdjustedCursorPosition(inputVal, newCursorPosition);
      element.setSelectionRange(adjustedPosition, adjustedPosition);
    } else {
      element.value = '';
    }
  }

  private getAdjustedCursorPosition(value: string, cursorPosition: number): number {
    // Recalculate cursor position based on non-digit characters (e.g., dashes)
    const digitCount = (value.slice(0, cursorPosition).match(/\d/g) || []).length;
    if (digitCount >= 3 && digitCount < 6) {
      return digitCount + 1; // After the first dash
    } else if (digitCount >= 6) {
      return digitCount + 2; // After the second dash
    }
    return digitCount;
  }


}
